import refundImg from '../images/refund-stamp.png';

export const reviewDetails = [ 
    { 
        img: refundImg, 
        name: 'Olanrewaju', 
        review: "I had been unemployed for a while, and I was starting to lose hope. Bubble AI gave me a new lease on life. They helped me identify my strengths and find jobs that aligned with my career goals. I'm now working in a fulfilling role.", 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Chijioke', 
        review: 'It helped me create a professional resume in just a few minutes. Highly recommended.', 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Oluwatoyin', 
        review: "I was struggling to find a job that aligned with my skills and passions. Bubble AI changed everything. They created a resume that perfectly showcased my abilities, and within weeks, I had multiple interview offers. I'm now working in my dream role!", 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Temi', 
        review: "I use the resume generator for my resume writing business, now I can generate more professional resumes in minutes. And in my business, more resumes mean more money. and all with little energy.", 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Emily', 
        review: "The AI resume creator is a lifesaver! It made the daunting task of creating a resume so much easier. The templates provided were professional. I would definitely recommend it to anyone in need of a professional resume.", 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Wale', 
        review: "Whoa, it’s so fast. Usually career people write my CVs in days with several corrections and review sessions. This took seconds and was even more professional. Ai is scary.", 
        rating: 4 
    }, 
    { 
        img: refundImg, 
        name: 'Lisa', 
        review: "The Job Suggestions after the resume creation were quite what I needed, it was like 3 platforms in one making the process easier. Really great tool, It could use more descrition though.", 
        rating: 4 
    }, 
    { 
        img: refundImg, 
        name: 'Mike', 
        review: "The AI resume creator is a game-changer. It helped me create a visually appealing and well-structured resume. The AI's suggestions for keywords and skills were spot-on. I would highly recommend it to job seekers. Rating: 5 out of 5.", 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Jessica', 
        review: "It is good because you can really get job in days or few weeks depending on your drive.", 
        rating: 4 
    }, 
    { 
        img: refundImg, 
        name: 'Ifeoma', 
        review: "I was struggling to stand out in a competitive job market. Bubble AI helped me create a resume that was truly unique. I landed multiple interviews and ultimately accepted a job offer with a great company. I highly recommend Bubble AI to anyone looking for a job.", 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Aisha', 
        review: "Wow, it gave me a CV with wonderful job descriptions and metrics that i could edit to fit my specific case. cover letter was tailored to my needs too. Nice project.", 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Chijindu', 
        review: "I had been applying for jobs for months with no luck. Bubble AI's targeted job matching was a game-changer. They connected me with opportunities I wouldn't have found on my own. I landed a great job in a field I'm passionate about.", 
        rating: 4.5 
    }, 
    { 
        img: refundImg, 
        name: 'Jay', 
        review: "The AI resume creator is a fantastic tool. It helped me create a professional resume that stands out. The AI's suggestions for keywords and skills were very helpful. I would give it a rating of 5 out of 5.", 
        rating: 5 
    }
];